* {
	margin: 0px;
	padding: 0px;
}
body {
	height: calc(100vh - 64px);
	background-color: #def3ff !important;
}
#root {
	height: calc(100vh - 64px);
	background-color: #def3ff;
}
a.menu-items:link {
	text-decoration: none;
	color: black;
}

@font-face {
	font-family: 'Cairo';
	src: local('Cairo'), url(./fonts/Cairo/Cairo-SemiBold.ttf) format('truetype');
}
